.footer-container {
  width: 100%;
  color: white;
  text-align: center;
  padding: 50px 50px;
  margin-top: 30px;
  background-color: black;
}


.footer-logo {
  max-width: 100px; /* o el tamaño que prefieras */
  height: auto;
  margin-bottom: 20px;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
  }
  
  .loader {
      width: 100px;
      height: 100px;
      aspect-ratio: 1;
      --g: #0000 calc(100% - 5px), #000 calc(100% - 3px) 93%, #0000;
      background: 
        radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
        radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
        radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
        radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
        radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
      background-repeat: no-repeat;
      animation: l20 1s infinite linear;
  }
  @keyframes l20 {
    100% {transform: rotate(1turn)}
  }


  .navbar {
    position: relative; 
    z-index: 10;
    background-color: white;
  }
  
  .video-container {
    z-index: 1;
  }